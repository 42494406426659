import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from "./components/nav/Navbar";
import {CssBaseline} from "@material-ui/core";
import Home from './pages/Home';

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <Navbar />
      <Switch>
        {/*<Route path="/" component={Home} />*/}
        <Route component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
