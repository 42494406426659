import React from "react";
import {Button, Container, createStyles, Grid, Link, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import abstractSquares from './abstract_squares.svg';
import boardImage from './board_crop.webp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      backgroundColor: theme.colors.accentColor2_100,
      backgroundImage: `url(${abstractSquares})`,
      backgroundSize: 'cover',
      maxWidth: '100%',
      width: '100%',
      height: '100vh',
    },
    contentContainer: {
      height: '100%',
    },
    img: {
      width: '80%',
    },
    buyNowButton: {
      color: theme.colors.primaryColor800,
      backgroundColor: theme.colors.accentColor2_400,
      '&:hover': {
        color: theme.colors.primaryColor700,
        backgroundColor: theme.colors.accentColor2_300,
        textDecoration: 'none',
      }
    },
  })
);

export default function Home() {
  const classes = useStyles();
  return (
      <>
        <Container className={classes.outerContainer} style={{height: 'calc(100vh - 64px)'}}>
          <Container className={classes.contentContainer} maxWidth={'lg'} component="main">
            <Grid container alignItems="center" style={{height: '100%'}}>
              <Grid item xs={6}>
                <img className={classes.img} alt="Delighto cheese board box with front and back" src={boardImage}/>
              </Grid>
              <Grid item>
                <Typography component="h2" variant="h6" color="textSecondary">NEW RELEASE</Typography>
                <Typography component="h1" variant="h2" color="textPrimary">CHEESE BOARD</Typography>
                <Typography component="h2" variant="h6" color="textSecondary">
                  Buy the best
                </Typography>
                <Typography component="h3" variant="h5" color="textSecondary">Only $49</Typography>
                <Link className={classes.buyNowButton} component={Button} href='https://www.amazon.com/dp/B088DM4B19'>
                  Buy Now
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </>
  );
}
