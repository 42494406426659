import React from "react";
import {AppBar, Button, createStyles, Link, Theme, Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DelightoLogoFull from "../DelightoLogoFull";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    buyNowButton: {
      color: theme.colors.primaryColor800,
      backgroundColor: theme.colors.accentColor2_400,
      '&:hover': {
        color: theme.colors.primaryColor700,
        backgroundColor: theme.colors.accentColor2_300,
        textDecoration: 'none',
      }
    },
    navbar: theme.mixins.toolbar,
  })
);

export default function Navbar() {
  const classes = useStyles();

  return (
      <>
        <AppBar position='fixed' className={classes.navbar}>
          <Toolbar className={classes.navbar}>
            <DelightoLogoFull height={42} />
            <div className={classes.grow} />
            <Link className={classes.buyNowButton} component={Button} href='https://www.amazon.com/dp/B088DM4B19'>Buy Now</Link>
          </Toolbar>
        </AppBar>
        <div className={classes.navbar} />
      </>
  );
}

