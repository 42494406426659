import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import React from "react";

const THEME_COLORS = {
  primaryColor100: 'hsl(214, 70%, 96%)',
  primaryColor200: 'hsl(214, 70%, 82%)',
  primaryColor300: 'hsl(214, 70%, 75%)',
  primaryColor400: 'hsl(214, 70%, 62%)',
  primaryColor500: 'hsl(214, 70%, 48%)',
  primaryColor600: 'hsl(214, 71%, 32%)',
  primaryColor700: 'hsl(208, 75%, 20%)',
  primaryColor800: 'hsl(209, 74%, 12%)',
  primaryColor900: 'hsl(214, 70%, 4%)',

  neutralColor100: 'hsl(210, 13%, 94%)',
  neutralColor200: 'hsl(219, 16%, 80%)',
  neutralColor300: 'hsl(219, 16%, 72%)',
  neutralColor400: 'hsl(219, 15%, 58%)',
  neutralColor500: 'hsl(219, 14%, 48%)',
  neutralColor600: 'hsl(219, 14%, 35%)',
  neutralColor700: 'hsl(219, 14%, 28%)',
  neutralColor800: 'hsl(221, 16%, 20%)',
  neutralColor900: 'hsl(220, 14%, 4%)',

  accentColor1_100: 'hsl(141, 90%, 92%)',
  accentColor1_500: 'hsl(141, 90%, 48%)',
  accentColor1_700: 'hsl(141, 90%, 24%)',
  accentColor1_900: 'hsl(141, 90%, 8%)',

  accentColor2_100: 'hsl(42,100%,92%)',
  accentColor2_200: 'hsl(42,100%,81%)',
  accentColor2_300: 'hsl(42, 100%, 72%)',
  accentColor2_400: 'hsl(42, 100%, 57%)',
  accentColor2_500: 'hsl(42, 100%, 41%)',
  accentColor2_600: 'hsl(42, 100%, 30%)',
  accentColor2_700: 'hsl(42, 100%, 21%)',
  accentColor2_800: 'hsl(42, 100%, 14%)',
  accentColor2_900: 'hsl(42, 100%, 8%)',

  accentColor3_100: 'hsl(0, 100%, 92%)',
  accentColor3_200: 'hsl(0, 98%, 79%)',
  accentColor3_300: 'hsl(0, 97%, 67%)',
  accentColor3_400: 'hsl(0, 97%, 58%)',
  accentColor3_500: 'hsl(0, 95%, 41%)',
  accentColor3_600: 'hsl(0, 93%, 33%)',
  accentColor3_900: 'hsl(0, 95%, 8%)',

  white: '#fff',
  lighten: '#ffffff2a',
};
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colors: typeof THEME_COLORS
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    colors?: typeof THEME_COLORS
  }
}
const THEME = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  colors: THEME_COLORS,
  mixins: {
    toolbar: {
      backgroundColor: 'hsl(208, 75%, 20%)',
      minHeight: 64,
      height: 64,
      // zIndex: 1049, // MDBootstrap modals are 1050, so we have to stay under that.
    }
  }
});

export default function SiteTheme(props: React.PropsWithChildren<{}>) {
  return <ThemeProvider theme={THEME}>
    {props.children}
  </ThemeProvider>
}
